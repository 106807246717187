import React from 'react'
import { useEffect } from 'react'
import SiteCore from '../scripts/SiteCore'
import '../components/styles/global.scss'
import '../components/styles/hcp.scss'
import { Seo } from '../components/common/Seo'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import HCPHero from '../components/common/HCP-Hero'
import HCPStudy from '../components/common/HCP-Study'
import HCPWhatIs from '../components/common/HCP-What-Is'
import HCPRopeginterferon from '../components/common/HCP-Ropeginterferon'
import HCPWhoCanTakePart from '../components/common/HCP-Who-Can-Take-Part'
import HCPLocations from '../components/common/HCP-Locations'
import HCPContact from '../components/common/HCP-Contact'
import CookieBanner from '../components/common/CookieBanner'
import { graphql } from 'gatsby'

export default function HCP({ data }) {

  const header = data.header.nodes[0],
        cookies = data.cookies.nodes[0],
        hero = data.hero.nodes[0],
        studydetails = data.studydetails.nodes[0],
        whatis = data.whatis.nodes[0],
        rope = data.rope.nodes[0],
        whocantakepart = data.whocantakepart.nodes[0],
        locations = data.locations.nodes[0],
        locationdata = data.locationdata.nodes[0],
        contact = data.contact.nodes[0],
        footer = data.footer.nodes[0],
        sitecore = new SiteCore()
    
  useEffect(() => {
    sitecore.init([], 'en')

    //  run an extra scroll update
    //  resolves bug where when reloading page the sticky class wasnt applied 
    //  to the nav bar
    sitecore.scrollUpdate()
  }, [])

  //console.log(data)

  return (
    <div id='microsite' className='hcp'>
      <Header lang='en' header={header}/>
      <div id='content-wrapper'>
        <HCPHero lang='en' hero={hero}/>
        <HCPStudy lang='en' studydetails={studydetails}/>
        <HCPWhatIs lang='en' whatis={whatis}/>
        <HCPRopeginterferon lang='en' rope={rope}/>
        <HCPWhoCanTakePart lang='en' whocantakepart={whocantakepart}/>   
        <HCPLocations
          lang='en'
          locations={locations}
          locationdata={locationdata}
          sitecore={sitecore}
        />    
        <HCPContact lang='en' contact={contact}/> 
        <Footer lang='en' footer={footer}/>
        <CookieBanner lang='en' cookies={cookies}/>
      </div>
    </div>
  )
}

export const Head = () => (
  <Seo
  pathname='/hcp/'
  title='EXCEED ET Clinical Study | Description & Eligibility Requirements'
  description='EXCEED ET is a study evaluating ropeginterferon alfa-2b-njft in the treatment of essential thrombocythemia. Learn if your patient is eligible today.'
  customSocialTitle='EXCEED ET Clinical Study | Description & Eligibility Requirements'
  customSocialDescription='EXCEED ET is a study evaluating ropeginterferon alfa-2b-njft in the treatment of essential thrombocythemia. Learn if your patient is eligible today.'
  />
)

export const query = graphql`
query HCP {
  cookies: allDataYaml(filter: {yamlId: {eq: "cookies"}}) {
    nodes {
      consent {
        en
      }
      accept {
        en
      }
      decline {
        en
      }
    }
  }
  header: allDataYaml(filter: {yamlId: {eq: "hcp-header"}}) {
    nodes {
      showFHP
      imgA {
        en
        src
      }
      linkA {
        en
        url
      }
      buttons {
        en
        anchor
      }
    }
  }
  hero: allDataYaml(filter: {yamlId: {eq: "hcp-hero"}}) {
    nodes {
      imgA {
        en
        src
      }
      imgB {
        en
        src
      }
      pA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      pD {
        en
      }
    }
  }
  studydetails: allDataYaml(filter: {yamlId: {eq: "hcp-study"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
      imgB {
        en
        src
      }
      pB {
        en
      }
      listA {
        en
      }
      pC {
        en
      }
      listB {
        en
      }
      pD {
        en
      }
      listC {
        en
      }
      study {
        title
        intro
        rowA {
          label
          period
        }
        rowB {
          label
          period
          triple
        }
        rowC {
          splitA
          rope
          splitB
          dose
        }
        rowD {
          label
          period
        }
      }
    }
  }
  whatis: allDataYaml(filter: {yamlId: {eq: "hcp-what-is-et"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
    }
  }
  rope: allDataYaml(filter: {yamlId: {eq: "hcp-ropeginterferon"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
    }
  }
  whocantakepart: allDataYaml(filter: {yamlId: {eq: "hcp-whocantakepart"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      listA {
        en
        img {
          en
          src
        }
      }
      imgB {
        en
        src
      }
      pA {
        en
      }
    }
  }
  locations: allDataYaml(filter: {yamlId: {eq: "hcp-locations"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
      hB {
        en
      }
      imgB {
        en
        src
      }
      pB {
        en
      }
      imgC {
        en
        src
      }
      pC {
        en
      }
    }
  }
  locationdata: allDataYaml(filter: {yamlId: {eq: "location-data"}}) {
    nodes {
      locations {
        en
        hasRegions
        isDisabled
        isOpen
        list {
          list {
            list {
              en
            }
            en
          }
          en
        }
      }
    }
  }
  contact: allDataYaml(filter: {yamlId: {eq: "hcp-contact"}}) {
    nodes {
      anchor
      imgA {
        src
        en
      }
      hA {
        en
      }
      pA {
        en
      }
      listA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      pD {
        en
      }
    }
  }
  footer: allDataYaml(filter: {yamlId: {eq: "footer"}}) {
    nodes {
      imgA {
        en
        src
      }
      pA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      linkA {
        en
        url
      }
      linkB {
        en
        url
      }
    }
  }
}
`