import React from 'react'

export default function HCPContact({ lang, contact}) {
    return (
      
        <div id={contact.anchor} className='nav-section'>
            <img className='dna-icon' src={contact.imgA.src} alt={contact.imgA[lang]}/>
            <div className='inner'>
                <div className='col'>
                    <h2 dangerouslySetInnerHTML={{__html: contact.hA[lang]}}/>
                    <p dangerouslySetInnerHTML={{__html: contact.pA[lang]}}/>
                    <ul>
                        {
                        contact.listA.map((contactItem, index)=>(
                        <li key={index}>{contactItem[lang]}</li>
                        ))
                        }
                    </ul>              
                </div>
            </div>
            <div className='row references'>
                <div className='col'><p>*</p></div>
                <div className='col'>
                    <p dangerouslySetInnerHTML={{__html: contact.pB[lang]}}/>
                    <p dangerouslySetInnerHTML={{__html: contact.pC[lang]}}/>
                    <p dangerouslySetInnerHTML={{__html: contact.pD[lang]}}/>
                </div>
            </div>  
        </div>
    )
  }